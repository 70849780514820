<template>
  <div class="openauthBox">
    <img src="../../assets/images/sz.jpg">
    <p>{{htmlText}}</p>
    <div>点击“头部<span>×</span>按钮”，关闭页面</div>
  </div>
</template>
<script>
export default {
  name: "openauth",
  data() {
    return {
      htmlText:""
    };
  },
  created() {
    document.getElementsByTagName("title")[0].innerText = "引导页";
    if (
      this.getQueryString("success") == "true" ||
      this.getQueryString("success") == "True"
    ) {
      this.htmlText="授权成功";
      this.$iToast('授权成功');
    } else {
      let msg = this.getQueryString("msg") || "授权失败";
      this.htmlText=msg;
      this.$iToast(msg);
    }
    const container = this.$cookies.get("container");
      switch (container) {
            case "browser":
              history.go(-3);
                // window.history.back(); 
                break;
            case "h5":
               history.go(-3);
                // window.history.back(); 
                break;
            case "native":
            default:
              if(window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", '');
              } else {
                this.iJsBridge.close();
              }
      }
    
  },
  mounted() {
    this.SmartStorage.set("type", "tenant");
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURI(r[2]);
      return null;
    },
  },
};
</script>
<style scoped="scoped" lang="scss">
.openauthBox{
  img{
    width: 50%;
    margin-top: 1rem;
  }
  p{
    margin-top: .3rem;
    font-size: .2rem;
  }
  div{
    span{
      font-size: .4rem;
      margin: .1rem;
      vertical-align: -0.2em;
    }
  }
}
</style>