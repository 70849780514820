import { render, staticRenderFns } from "./openauth.vue?vue&type=template&id=4b87d1d2&scoped=true&"
import script from "./openauth.vue?vue&type=script&lang=js&"
export * from "./openauth.vue?vue&type=script&lang=js&"
import style0 from "./openauth.vue?vue&type=style&index=0&id=4b87d1d2&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b87d1d2",
  null
  
)

export default component.exports